



import WILAYAH      from "../../library/getWilayah"  ;
import CHART        from '../../library/chart'       ;
import FETCHING     from '../../library/fetching'    ;




import Joi from "joi";
const schema = Joi.object().keys({
    username: Joi.string().regex(/^[a-zA-Z0-9_]*$/).min(3).max(13).required(),
    password: Joi.string().min(6).required(),
  });
import UMUM from  '../../library/umum.js'


export default {
  data() {
    return {

        form : {
            id : '',
            uraian : '',
            createdAt : '',
        },

        dataku: {
            id : '',
            level : '',
            aksesWilayah : 0,
            des_kel : '',
            kecamatan : '',
            kelasAdmin : '',
            nama : '',
            username : "",
            password : "",
            confirmPassword: "",
            email: "",
            hp: "",
        },
        list_data : [],
        list_des_kel : [],
        list_level : [],
     
         errorMessage: '',

        page_limit : 8,
        page_first: 1,
        page_last: 0,
        cari_value: "",
        cek_load_data : true,


        mdl_add: false,
        mdl_edit: false,
        mdl_hapus : false,
        btn_add: false,
    }
  },
  methods: {


    getView: function() {

        // console.log("TELASOOOOOOOOOOOOOOOOOOO")

        this.$store.commit("shoWLoading");
        fetch(this.$store.state.url.URL_REGISTRASI + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                data_ke: this.page_first,
                page_limit : this.page_limit,
                cari_value: this.cari_value
            })
        })
            .then(res => res.json())
            .then(res_data => {
                this.list_data = res_data.data;
                this.page_last = res_data.jmlData;
                this.$store.commit("hideLoading");
                console.log(res_data);
        });
    },

    addData: function() {
      this.setAksesWilayah();
      this.errorMessage = "";
        if (this.validUser()) {
            // Jika user sdh valid lakukan pengiriman data ke server
            const body = {
              username: this.dataku.username,
              password: this.dataku.password
            };
            this.signingUp = true;
            fetch(this.$store.state.url.URL_REGISTRASI+'signup', {
                method: "POST",
                body: JSON.stringify(this.dataku),
                headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                }
            }).then((response) => {
                this.signingUp = false;
                if (response.ok) {
                    this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
                    this.getView();
                    // this.$router.push('/login');
                    // return response.json();
                }
                else{
                  return response.json().then(error => {
                    throw new Error(error.message);
                  });

                }
            })
            .catch((error) => {
              setTimeout(() => {
                this.signingUp = false;
                this.errorMessage = error.message;
              }, 1000);
            });
        }else{
          console.log("tidak valid");

        }

    },

    editData: function() {
        this.setAksesWilayah();

        fetch(this.$store.state.url.URL_REGISTRASI + "editData", {
            method: "POST",
            headers: {
                'content-type' : 'application/json',
                authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                id : this.dataku.id,
                level : this.dataku.level,
                kelasAdmin : this.dataku.kelasAdmin,
                nama : this.dataku.nama,
                username : this.dataku.username,
                email: this.dataku.email,
                hp: this.dataku.hp,

                des_kel: this.dataku.des_kel,
                kecamatan: this.dataku.kecamatan,
                aksesWilayah: this.dataku.aksesWilayah,

            })
        }).then(res_data => {
            this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
            this.getView();
        });
    },

    removeData: function(idnya) {
        fetch(this.$store.state.url.URL_REGISTRASI + "removeData", {
            method: "POST",
            headers: {
                "content-type": "application/json",
                authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                id: idnya,
            })
        }).then(res_data => {
            this.getView();
            this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
        });
    },


    getLevel: function() {
        fetch(this.$store.state.url.URL_LEVEL_USERS + "list", {
            method: "GET",
            headers: {
                "content-type": "application/json",
                authorization: "kikensbatara " + localStorage.token
            },
          
        })
            .then(res => res.json())
            .then(res_data => {
                this.list_level = res_data;
                // this.$store.commit("hideLoading");
                console.log(res_data);
        });
    },

    validUser : function(){
      if (this.dataku.password !== this.dataku.confirmPassword) {
          this.errorMessage = "Password dan Confirm Password harus sama. !";
          return false;
      }
      // Memulai proses validasi melalui skema Joi yang sebelumnya dibuat
      // req.body merupakan data yang di kirim oleh client dan schema merupakan skema joi yg di buat sebelumnya
      const body = {
              username: this.dataku.username,
              password: this.dataku.password
      };
      const result = Joi.validate(body, schema);

      if (result.error === null) {
          return true;
      }
      if (result.error.message.includes("username")) {
          // jika pesan error yang dihasilkan mengandung char "username" maka
          this.errorMessage = "Username tidak valid (Min : 6 dan Max : 14 Karakter)";
      } else {
          this.errorMessage = "Password tidak valid (Min : 6 Karakter)";
        //   console.log(result.error);

      }
      return false;
    },

    selectData : function(data){
        this.dataku.id = data.id;
        this.dataku.nama = data.nama;
        this.dataku.level = data.level;
        this.dataku.kelasAdmin = data.kelasAdmin;
        this.dataku.username = data.username;
        this.dataku.email = data.email;
        this.dataku.hp = data.hp;

        this.dataku.des_kel = data.des_kel;
        this.dataku.kecamatan = data.kecamatan;
        this.dataku.aksesWilayah = data.aksesWilayah;

    },



    filterDesKel : function (val, update, abort) {
        console.log(val)
        update(() => {
          if (val === '') {}
          else {WILAYAH.postDesKelAutocomplete(val)}
        })
    },

    setAksesWilayah : function(){

      if (this.dataku.aksesWilayah == 0) {
        console.log("AKSES DESA DI SET")
        this.dataku.kecamatan = null
      } 
      else if(this.dataku.aksesWilayah == 1){
        console.log("AKSES KECAMATAN DI SET")
        this.dataku.des_kel = null
      }
      else if(this.dataku.aksesWilayah == 2){
        console.log("AKSES KABUPATEN DI SET")
        this.dataku.kecamatan = null
        this.dataku.des_kel = null
      }
    },




    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx
        }




    // ====================================== PAGINATE ====================================







  },

  mounted () {
    this.getView();
    this.getLevel();
  },
}
